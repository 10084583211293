export const saleStatuses = {
    PLANNING: 'Planning',
    PLANNED: 'Planned',
    COMPLETE: 'Complete',
    CANCELED: 'Cancelled',
    ACTIVE: 'Active',
    ACTIVE_AUCTION: 'ActiveAuction',
    ACTIVE_QUALIFICATION: 'ActiveQualification',
    ACTIVE_QUALIFICATION_BACKUPS: 'ActiveQualificationBackups',
};

const titles = {
    [saleStatuses.PLANNING]: 'Planning',
    [saleStatuses.PLANNED]: 'Planned',
    [saleStatuses.COMPLETE]: 'Complete',
    [saleStatuses.CANCELED]: 'Cancelled',
    [saleStatuses.ACTIVE]: 'Active',
    [saleStatuses.ACTIVE_AUCTION]: 'Active',
    [saleStatuses.ACTIVE_QUALIFICATION]: 'Pending',
    [saleStatuses.ACTIVE_QUALIFICATION_BACKUPS]: 'Pending',
};

export function getSaleStatusTitle(status) {
    return titles[status] || '-';
}
