<template>
    <div class="custom-table">
        <div class="table-head black white--text">
            <div class="column column-1" />
            <div class="column column-2">
                Sale type
            </div>
            <div class="column column-3">
                Stage
            </div>
            <div class="column column-4">
                Start date
            </div>
            <div class="column column-5">
                End date
            </div>
            <div class="column column-6">
                Price
            </div>
            <div class="column column-7">
                Bidders
            </div>
            <div class="column column-8" />
        </div>
        <div class="table-body">
            <TableRow
                :sale="sale"
                :asset-id="assetId"
                :is-sale-editable="isSaleEditable"
                @updateCurrentBid="value => $emit('updateCurrentBid', value)"
                @updateEndDate="value => $emit('updateEndDate', value)"
                @updateSaleStatus="value => $emit('updateSaleStatus', value)"
                @updateEnglishAuction="value => $emit('updateEnglishAuction', value)"
                @updateParticipations="value => $emit('updateParticipations', value)"
            />
        </div>
    </div>
</template>

<script>
import TableRow from '@/components/Asset/AssetShortInfo/TableRow';

export default {
    components: { TableRow },
    props: {
        sale: {
            type: Object,
            required: true,
        },
        assetId: {
            type: String,
        },
        isSaleEditable: Boolean,
    },
};
</script>

<style scoped lang="scss">
.custom-table {
    .table-head {
        display: flex;
    }
    .column {
        padding: 15px;
        flex-grow: 1;
        font-size: 0.75rem;
        font-weight: bold;
        width: 15%;
        &-1 {
            width: 5%;
        }
        &-2,
        &-7 {
            width: 12%;
        }
        &-8 {
            width: 7%;
        }

        .show-details-button {
            cursor: pointer;
        }
    }

    .table-body {
        .table-row {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            &:not(:last-of-type) {
                border-bottom: 1px solid #cccccc;
            }
            .table-row-details {
                width: 100%;

                .details-table-head {
                    background-color: darkgray;

                    th {
                        height: 32px;
                    }
                }
            }
        }
    }

    .listing-text {
        color: darkgray;
        font-size: 0.75rem;
    }
}
</style>