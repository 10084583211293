export const saleKinds = {
    LISTING_SALE: 'listingSale',
    ENGLISH_AUCTION: 'englishAuction',
};

const mutations = {
    [saleKinds.LISTING_SALE]: 'switchListingStatus',
    [saleKinds.ENGLISH_AUCTION]: 'switchUserEnglishAuctionStatus',
};

export function getSwitchStatusMutation(kind) {
    if (!mutations[kind]) throw new Error('Mutation does not exist');
    return mutations[kind];
}
