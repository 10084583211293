import { API } from '@aws-amplify/api';

export default {
    onSetCurrentBid(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription onSetCurrentBid($id: String!) {
            onSetCurrentBid(id: $id) {
                id
                englishAuction {
                    currentBidValue
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },

    switchStatus(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `subscription switchStatus($id: String!) {
            switchStatus(id: $id) {
                id
                address {
                    address
                    city
                    state
                    zip
                }
                kind
                images {
                    mainImage
                }
                agent {
                    firstName
                    lastName
                }
                sale {
                    kind
                    englishAuction {
                        endDate
                    }
                    status
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },

    onMySwitchStatus(owner) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;

        const query = `subscription onMySwitchStatus($owner: String!) {
            onMySwitchStatus(owner: $owner) {
                ... on ResidentialAsset {
                    id
                    address {
                        address
                        city
                        state
                        zip
                    }
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }
                    sale {
                        kind
                        englishAuction {
                            endDate
                        }
                        status
                    }
                }
                ... on CommercialAsset {
                    id
                    address {
                        address
                        city
                        state
                        zip
                    }
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }
                    sale {
                        kind
                        englishAuction {
                            endDate
                        }
                        status
                    }
                }
                ... on LandAsset {
                    id
                    address {
                        address
                        city
                        state
                        zip
                    }
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }
                    sale {
                        kind
                        englishAuction {
                            endDate
                        }
                        status
                    }
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                owner,
            },
        });
    },

    onEditAuction(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription onEditAuction($id: String!) {
            onEditAuction(id: $id) {
                id
                status
                englishAuction {
                    endDate
                    startDate
                    startingPrice
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        }).map(({ value }) => {
            return value.data.onEditAuction;
        });
    },

    switchEnglishAuctionStatus(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription switchEnglishAuctionStatus($id: String!) {
            switchEnglishAuctionStatus(id: $id) {
                id
                status
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },
};
