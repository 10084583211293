<template>
    <v-card class="pa-5">
        <div
            v-if="loading"
            class="d-flex justify-center"
        >
            <v-progress-circular
                size="48"
                indeterminate
            />
        </div>
        <template v-else>
            <div class="mb-4">
                <div class="d-flex align-center justify-space-between flex-wrap mb-2">
                    <div class="title d-flex align-center font-weight-bold">
                        <v-icon
                            size="25"
                            class="mr-3"
                        >
                            mdi-file-document-outline
                        </v-icon>
                        <div
                            v-if="asset.kind === 'Basic'"
                            class="mr-3"
                        >
                            {{ asset.title }}
                        </div>
                        <div
                            v-if="asset.address"
                            class="mr-3"
                        >
                            <span>
                                {{ asset.address.address }},
                            </span>
                            <span class="font-weight-regular">
                                {{ asset.address.city }},
                                {{ asset.address.state }}
                                {{ asset.address.zip ? `${asset.address.zip}` : '' }}
                            </span>
                        </div>
                    </div>
                    <v-btn
                        text
                        color="red"
                        class="ml-5 px-2"
                        :to="`/assets/${asset.id}/${asset.kind}`"
                    >
                        <template v-if="isDeleted">
                            <v-icon
                                size="20"
                                class="mr-1"
                            >
                                mdi-eye-outline
                            </v-icon>
                            View listing
                        </template>
                        <template v-else>
                            <v-icon
                                size="20"
                                class="mr-1"
                            >
                                mdi-pencil
                            </v-icon>
                            Edit asset
                        </template>
                    </v-btn>
                </div>
                <div class="pa-4 pl-0 d-flex flex-wrap flex-sm-nowrap">
                    <v-img
                        height="200"
                        width="300"
                        max-height="200"
                        max-width="300"
                        :src="assetImage"
                        class="grey lighten-2 mr-sm-5"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                />
                            </v-row>
                        </template>
                    </v-img>
                    <div class="row ml-sm-0 align-start align-content-start">
                        <div class="col-12">
                            Asset Type: <span class="font-weight-bold">{{ asset.kind }}</span>
                        </div>
                        <template v-if="asset.kind !== 'Basic'">
                            <div
                                v-for="(value, key) in asset.data.features"
                                :key="key"
                                class="col-6 py-1 pr-2"
                            >
                                <span class="feature">{{ featureToText(value, key) }}</span>
                            </div>
                        </template>
                    </div>
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="mb-8"
                    v-html="asset.description"
                />
                <!--eslint-enable-->
            </div>
            <div
                v-if="asset.status !== DELETED"
                class="short-info-buttons mb-4"
            >
                <div
                    class="d-flex"
                    :class="{'justify-space-between': !hideMarkAsSold}"
                >
                    <v-tooltip
                        v-if="disablePublish"
                        top
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-btn
                                    disabled
                                    color="black"
                                    class="white--text"
                                >
                                    {{ !asset.sale ? 'Publish' : 'Republish' }}
                                </v-btn>
                            </div>
                        </template>
                        <span class="font-weight-bold">The asset cannot be republished until the sale is stopped</span>
                    </v-tooltip>

                    <v-btn
                        v-else
                        router
                        color="black"
                        class="white--text"
                        :to="{name: 'create-sale', params: {id: asset.id, kind: asset.kind}}"
                    >
                        {{ !asset.sale ? 'Publish' : 'Republish' }}
                    </v-btn>


                    <v-tooltip
                        v-if="disableSoldMarking"
                        top
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-btn
                                    disabled
                                    text
                                >
                                    Mark as sold
                                </v-btn>
                            </div>
                        </template>
                        <span class="font-weight-bold">The asset cannot be marked as sold until the sale is stopped</span>
                    </v-tooltip>

                    <v-btn
                        v-else-if="!hideMarkAsSold && !disableSoldMarking"
                        text
                        @click="markAsSold"
                    >
                        Mark as sold
                    </v-btn>
                </div>
            </div>

            <AssetShortInfoTable
                v-if="asset.sale"
                :sale="asset.sale"
                :is-sale-editable="isSaleEditable"
                :asset-id="asset.id"
                @updateCurrentBid="updateCurrentBid"
                @updateEndDate="updateEndDate"
                @updateSaleStatus="updateSaleStatus"
                @updateEnglishAuction="updateEnglishAuction"
                @updateParticipations="updateParticipations"
            />
        </template>
        <AppOverlaySpinner v-if="overlayLoading" />
    </v-card>
</template>

<script>
import assetApi from '@/services/apiCalls/asset';
import { Storage } from 'aws-amplify';
import { Utils } from '@/utils';
import AssetShortInfoTable from '@/components/Asset/AssetShortInfo/AssetShortInfoTable';
import { DELETED } from '@/utils/filters';
import { saleStatuses } from '@/constants/saleStatuses';
import { getSwitchStatusMutation } from '@/constants/saleKinds';
import AppOverlaySpinner from '@/components/App/AppOverlaySpinner';

export default {
    name: 'AssetShortInfo',
    components: {
        AssetShortInfoTable,
        AppOverlaySpinner,
    },
    data() {
        return {
            loading: false,
            asset: null,
            assetImage: '',
            agents: ['First agent', 'Second agent', 'Third agent'],
            DELETED,
            overlayLoading: false,
        };
    },
    computed: {
        disablePublish() {
            if (!this.asset.sale) return;
            return [
                saleStatuses.ACTIVE,
                saleStatuses.PLANNING,
                saleStatuses.PLANNED,
                saleStatuses.ACTIVE_AUCTION,
                saleStatuses.ACTIVE_QUALIFICATION,
                saleStatuses.ACTIVE_QUALIFICATION_BACKUPS,
            ].includes(this.asset.sale?.status);
        },
        disableSoldMarking() {
            return [saleStatuses.PLANNED, saleStatuses.PLANNING, saleStatuses.ACTIVE_AUCTION].includes(
                this.asset.sale?.status,
            );
        },
        hideMarkAsSold() {
            return !this.asset.sale || [saleStatuses.COMPLETE].includes(this.asset.sale.status);
        },
        isDeleted() {
            return this.asset.status === DELETED;
        },
        isSaleEditable() {
            return !this.isDeleted && ![saleStatuses.CANCELED, saleStatuses.COMPLETE].includes(this.asset.sale?.status);
        },
    },
    async created() {
        if (this.$route.params.id) {
            try {
                this.loading = true;
                const { id, kind } = this.$route.params;
                const { data } = await assetApi.getAssetShortInfo(id, kind);
                this.asset = data[`get${kind}Asset`];
                this.assetImage = await Storage.get(Utils.extractFileKey(this.asset.images.mainImage), { level: 'public' });
            } catch (e) {
                this.$store.dispatch('handleError', e);
                this.$router.push('/assets');
            } finally {
                this.loading = false;
            }
        }
    },
    methods: {
        featureToText(value, key) {
            const features = {
                type: `${value}`,
                squareFootage: `${value} Sq. Ft.`,
                baths: `${value} bathrooms`,
                beds: `${value} bedrooms`,
                yearBuilt: `Built in ${value}`,
                totalAcres: `${value} acres`,
            };

            return features[key];
        },
        async markAsSold() {
            const mutation = getSwitchStatusMutation(this.asset.sale.kind);
            try {
                this.overlayLoading = true;
                await assetApi[mutation](this.asset.sale.id, saleStatuses.COMPLETE);

                this.$store.commit('snackbar', {
                    text: 'Asset status was changed successfully',
                    color: 'success',
                    isOpened: true,
                });
                this.$router.push('/assets?tab=Sold');
            } catch (e) {
                this.$store.dispatch('handleError', e);
            } finally {
                this.overlayLoading = false;
            }
        },
        updateCurrentBid(newValue) {
            if (!newValue) return;
            this.asset.sale.englishAuction.currentBidValue = newValue;
        },
        updateEndDate(value) {
            if (!value) return;
            this.asset.sale.englishAuction.endDate = value;
        },
        updateSaleStatus(status) {
            if (!status) return;
            this.asset.sale.status = status;
        },
        updateEnglishAuction(englishAuction) {
            if (typeof englishAuction === 'object') {
                this.asset.sale.englishAuction = {
                    ...this.asset.sale.englishAuction,
                    ...englishAuction,
                };
            }
        },
        updateParticipations(englishAuction) {
            this.asset.sale.englishAuction = {
                ...this.asset.sale.englishAuction,
                ...englishAuction,
            };
        },
    },
};
</script>
