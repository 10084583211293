import { API } from '@aws-amplify/api';

export default {
    switchEnglishAuctionStatus(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription switchEnglishAuctionStatus($id: String!) {
            switchEnglishAuctionStatus(id: $id) {
                status
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },

    onSetCurrentBid(id) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription onSetCurrentBid($id: String!) {
            onSetCurrentBid(id: $id) {
                id
                englishAuction {
                    currentBidValue
                    currentBidOwner
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        });
    },

    onCreateParticipationSale({ saleId }) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription onCreateParticipationSale($saleId: String!) {
            onCreateParticipationSale(saleId: $saleId) {
                bid {
                    id
                    status
                    bidValue
                    createdAt
                    owner
                    name
                }
                personalInfo {
                    firstName
                    lastName
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                saleId,
            },
        }).map(({ value }) => {
            return value.data.onCreateParticipationSale;
        });
    },

    onChangedSaleParticipation({ id }) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[1].endpoint;

        const query = `subscription onChangedSaleParticipation($id: String!) {
            onChangedSaleParticipation(id: $id) {
                englishAuction {
                    approvedParticipations
                    pendingParticipations
                }
            }
        }`;

        return API.graphql({
            query,
            variables: {
                id,
            },
        }).map(({ value }) => {
            return value.data.onChangedSaleParticipation;
        });
    },
};
