<template>
    <div class="table-row">
        <div class="column column-1">
            <v-icon
                color="black"
                class="show-details-button"
                @click="showDetails = !showDetails"
            >
                {{ showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
        </div>
        <div class="column column-2">
            {{ sale.kind === saleKinds.ENGLISH_AUCTION ? 'English' : 'Listing' }}
        </div>
        <div class="column column-3">
            {{ getSaleStatusTitle() }}
        </div>
        <div class="column column-4">
            {{ getStartDate() }}
        </div>
        <div class="column column-5">
            {{ getEndDate() }}
        </div>
        <div class="column column-6">
            {{ getPrice() }}
        </div>
        <div class="column column-7">
            <div v-if="sale.kind === saleKinds.ENGLISH_AUCTION">
                <template v-if="sale.englishAuction.pendingParticipations">
                    <span class="blue--text">{{ sale.englishAuction.pendingParticipations }}</span> +
                </template>
                {{ sale.englishAuction.approvedParticipations || '0' }}
            </div>
            <span v-else>-</span>
        </div>
        <div class="column column-8">
            <router-link
                :to="publicationLink()"
                class="text-decoration-none"
            >
                <v-icon v-if="isSaleEditable">
                    mdi-pencil
                </v-icon>
                <v-icon v-else>
                    mdi-eye-outline
                </v-icon>
            </router-link>
        </div>
        <div
            v-if="showDetails"
            class="table-row-details pl-10"
            @scroll="onScroll"
        >
            <v-simple-table
                class="details-table"
            >
                <template v-slot:default>
                    <thead class="mb-3 details-table-head">
                        <tr>
                            <th class="text-left font-weight-bold white--text">
                                Bidder Name
                            </th>
                            <th class="text-left font-weight-bold white--text">
                                Bidder Number
                            </th>
                            <th class="text-left font-weight-bold white--text">
                                Bids
                            </th>
                            <th class="text-left font-weight-bold white--text">
                                Date
                            </th>
                            <th class="text-left font-weight-bold white--text">
                                Bidder status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <p
                            v-if="sale.kind === saleKinds.LISTING_SALE"
                            class="listing-text font-weight-bold mt-3 pl-3"
                        >
                            There is no bidding details for a listing
                        </p>
                        <template
                            v-else
                        >
                            <router-link
                                v-for="bid in bids"
                                :key="bid.id"
                                :to="`/users/${bid.owner}`"
                                tag="tr"
                            >
                                <th>
                                    {{ bid.personalInfo.firstName }}
                                    {{ bid.personalInfo.lastName }}
                                </th>
                                <th>
                                    <span v-if="$store.state.user.sub === bid.owner">
                                        You
                                    </span>
                                    <span v-else>
                                        {{ bid.name || 'Bidder' }}
                                    </span>
                                </th>
                                <th>{{ formatPrice(bid.bidValue) }}</th>
                                <th>{{ formatBidDate(bid.createdAt) }}</th>
                                <th :class="{ 'red--text': bid.status ==='Pending' }">
                                    {{ bid.status }}
                                </th>
                            </router-link>
                            <tr v-if="loading">
                                <th
                                    class="text-center"
                                    colspan="5"
                                >
                                    <v-progress-circular
                                        size="32"
                                        indeterminate
                                    />
                                </th>
                            </tr>
                            <p
                                v-else-if="!loading && !bids.length"
                                class="listing-text font-weight-bold mt-3 pl-3"
                            >
                                There are no active bidders in the auction yet
                            </p>
                        </template>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </div>
</template>

<script>
import assetApi from '@/services/apiCalls/asset';
import { Utils } from '@/utils';
import { saleKinds } from '@/constants/saleKinds';
import { getSaleStatusTitle } from '@/constants/saleStatuses';
import assetSubscriptions from '@/services/subscriptions/asset';
import saleSubscriptions from '@/services/subscriptions/sale';

export default {
    props: {
        sale: {
            type: Object,
            required: true,
        },
        assetId: {
            type: String,
        },
        isSaleEditable: Boolean,
    },
    data() {
        return {
            saleKinds,
            showDetails: true,
            bids: [],
            loading: false,
            nextToken: null,
            lastPage: false,
        };
    },
    created() {
        if (this.assetId && this.sale.kind === saleKinds.ENGLISH_AUCTION) {
            const currentBidSubscription = assetSubscriptions.onSetCurrentBid(this.sale.id).subscribe(
                ({
                    value: {
                        data: { onSetCurrentBid: bid },
                    },
                }) => {
                    this.$emit('updateCurrentBid', bid?.englishAuction?.currentBidValue);
                },
            );

            const onEditAuctionSubscription = assetSubscriptions
                .onEditAuction(this.sale.id)
                .subscribe(({ englishAuction, status }) => {
                    this.$emit('updateEnglishAuction', englishAuction);
                    this.$emit('updateSaleStatus', status);
                });

            const switchEnglishAuctionStatusSubscription = assetSubscriptions
                .switchEnglishAuctionStatus(this.sale.id)
                .subscribe(
                    ({
                        value: {
                            data: { switchEnglishAuctionStatus },
                        },
                    }) => {
                        this.$emit('updateSaleStatus', switchEnglishAuctionStatus.status);
                    },
                );

            const onCreateParticipationSaleSubscription = saleSubscriptions
                .onCreateParticipationSale({ saleId: this.sale.id })
                .subscribe((participation) => {
                    this.onCreateParticipationSale(participation);
                });

            const onChangedSaleParticipationSubscription = saleSubscriptions
                .onChangedSaleParticipation({ id: this.sale.id })
                .subscribe(({ englishAuction }) => {
                    this.$emit('updateParticipations', englishAuction);
                });

            this.$once('hook:beforeDestroy', () => {
                currentBidSubscription.unsubscribe();
                onEditAuctionSubscription.unsubscribe();
                switchEnglishAuctionStatusSubscription.unsubscribe();
                onCreateParticipationSaleSubscription.unsubscribe();
                onChangedSaleParticipationSubscription.unsubscribe();
            });

            this.loadBids();
        }
    },
    methods: {
        getPrice() {
            const price =
                this.sale.englishAuction?.currentBidValue ||
                this.sale.englishAuction?.startingPrice ||
                this.sale.listingSale?.listingPrice;

            return price ? '$' + Utils.formatPrice(price) : '-';
        },
        getStartDate() {
            const date =
                this.sale.kind === saleKinds.LISTING_SALE ? this.sale?.createdAt : this.sale?.englishAuction?.startDate;
            return date ? Utils.formatDate(date) : '-';
        },
        getEndDate() {
            if (this.sale.kind !== saleKinds.ENGLISH_AUCTION) return '-';

            return this.sale.englishAuction.endDate ? Utils.formatDate(this.sale.englishAuction.endDate) : '-';
        },
        publicationLink() {
            const { id, kind } = this.$route.params;

            return `/assets/publication/edit/${id}/${kind}`;
        },
        getSaleStatusTitle() {
            return getSaleStatusTitle(this.sale.status);
        },
        formatPrice(price) {
            return price ? '$' + Utils.formatPrice(price) : '-';
        },
        formatBidDate(date) {
            if (!date) return '-';
            return Utils.formatDate(date);
        },
        async loadBids() {
            try {
                this.loading = true;
                const {
                    data: { listAuctionBids },
                } = await assetApi.listAuctionBids({ saleId: this.sale.id, nextToken: this.nextToken, limit: 5 });
                if (listAuctionBids.nextToken) {
                    this.nextToken = listAuctionBids.nextToken;
                } else {
                    this.lastPage = true;
                }
                this.bids = [...this.bids, ...listAuctionBids.data];
            } catch (e) {
                this.$store.dispatch('handleError', e);
            } finally {
                this.loading = false;
            }
        },
        onScroll(e) {
            if (this.loading || this.lastPage) return;
            const isScrollOnBottom = e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight;
            if (isScrollOnBottom) this.loadBids();
        },
        onCreateParticipationSale({ bid, personalInfo }) {
            const newBid = {
                ...bid,
                personalInfo,
            };
            this.bids.unshift(newBid);
        },
    },
};
</script>

<style scoped lang="scss">
.custom-table {
    .table-head {
        display: flex;
    }
    .column {
        padding: 15px;
        flex-grow: 1;
        font-size: 0.75rem;
        font-weight: bold;
        width: 15%;
        &-1 {
            width: 5%;
        }
        &-2,
        &-7 {
            width: 12%;
        }
        &-8 {
            width: 7%;
        }

        .show-details-button {
            cursor: pointer;
        }
    }

    .table-body {
        .table-row {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            &:not(:last-of-type) {
                border-bottom: 1px solid #cccccc;
            }
            .table-row-details {
                overflow: auto;
                width: 100%;
                max-height: 250px;

                .details-table-head {
                    th {
                        height: 32px;
                        background-color: darkgray;
                    }
                }
            }
        }
    }

    .listing-text {
        color: darkgray;
        font-size: 0.75rem;
    }

    .details-table {
        max-height: 300px;
    }
}
</style>